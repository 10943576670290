import React, { Component } from 'react'
import { Row, Col, List, Icon, ConfigProvider, Calendar, Collapse, Table, Badge, Divider, Button, Card } from 'antd'

import ContainerDimensions from 'react-container-dimensions'

import Map from 'pigeon-maps/react'
import Marker from 'pigeon-marker/react'

import LazyLoad from 'react-lazyload';

import de_DE from 'antd/lib/locale-provider/de_DE';
import moment from 'moment';
import 'moment/locale/de';

import event_recurrent from '../functions/event_recurrent'
import sort from './../functions/dependency_sorting'

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const { Meta } = Card
const Panel = Collapse.Panel;

var ReactMarkdown = require('react-markdown');

const dateConfiguration = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
Object.freeze(dateConfiguration);

const EventType = {'PUBLIC': 'public', 'INTERN': 'intern', 'TRAINING': 'training'};
Object.freeze(EventType);

const ContentType = {'pdf': 'application/pdf'};
Object.freeze(EventType);

const markDownRenderer = {
    image: (props) => (
        <LazyLoad offset={100} once placeholder={<span className="figure"/>}>
            <span className="figure">
                <img
                    src={props.src}
                    alt={props.alt}
                    title={props.alt}
                />
                <span className="figcaption">{(props.alt || '').split(/\n/).map((text,index) => (
                    <span key={index}>{text}</span>
                ))}</span>
            </span>
        </LazyLoad>
    )
};

const inlineDownload = (axios, href, title) => {
    const ext = href.match(new RegExp('\\.([^\\./\\?]+)($|\\?)'))[0],
          filename = title + ext

    axios({
        url: href,
        method: 'GET',
        responseType: 'blob', // important
    }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: ContentType[ ext]}));
        const link = document.createElement('a');

        link.href = url;
        link.setAttribute('download', filename)

        document.body.appendChild(link);
        link.click();
    });

};

class Markdown extends Component {
    componentDidMount() {
        this.props.application.setState({isLoading: false})
    }

    render() {
        return(
            <section>
                <h2>{this.props.content.title}</h2>
                <ReactMarkdown
                    source={this.props.content.content}
                    renderers={markDownRenderer}
                />
            </section>
        )
    }
}

class Contact extends Component {
    state = {
        contacts: []
    };

    async componentDidMount() {
        const {strapi} = this.props.application;

        const contacts = sort(
            await strapi.getEntries('contacts', {})
        );

        this.setState({
            contacts: contacts
        });

        this.props.application.setState({isLoading: false})
    }

    render() {
        const { application: { strapi, isMobile} } = this.props;
        const { contacts } = this.state;

        return(
            <section className="contacts">
                <ReactMarkdown
                    source={this.props.content.content}
                    renderers={{
                        image: (props) => (
                            <span className="figure">
                                <img
                                    src={props.src}
                                    alt={props.alt}
                                    title={props.alt}
                                />
                                <span className="figcaption">{props.alt}</span>
                            </span>
                        )
                    }}
                />
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={contacts}
                    renderItem={contact => (
                        <List.Item
                            key={contact.id}
                        >
                            <List.Item.Meta
                                avatar={!isMobile && contact.picture ?
                                    <img
                                        src={ strapi.axios.defaults.baseURL+contact.picture.url} style={{width: 200}}
                                        alt={contact.name}
                                        title={contact.name}
                                     />
                                : ''}
                                title={contact.name}
                                description={
                                    <div>
                                        {contact.function}<br/>
                                        {contact.street ?
                                            <div>
                                                <br/>
                                                <div style={{float: 'left'}}>
                                                    <Icon type="environment" />&#160;
                                                </div>
                                                <div style={{float: 'left'}}>
                                                    {contact.street}<br/>
                                                    {contact.zip} {contact.city}
                                                </div>
                                            </div>
                                        : ''}
                                        <br style={{clear: 'both'}}/>
                                        <br />
                                        {contact.phone ? <div>
                                            <Icon type="phone" /> <a href={'tel:'+contact.phone.replace(/\+/, '00').replace(/[^\d]+/g, '')}>{contact.phone}</a>
                                        </div> : ''}
                                        {contact.mobile ? <div>
                                            <Icon type="mobile" /> <a href={'tel:'+contact.mobile.replace(/\+/, '00').replace(/[^\d]+/g, '')}>{contact.mobile}</a>
                                        </div> : ''}
                                        {contact.email ? <div>
                                            <Icon type="mail" /> <a href={'mailto:'+contact.email}>{contact.email}</a>
                                        </div> : ''}
                                        { isMobile && contact.picture ? (
                                            <img
                                                src={ strapi.axios.defaults.baseURL+contact.picture.url} style={{width: '100%'}}
                                                alt={contact.name}
                                                title={contact.name}
                                            />
                                        ) : ''}
                                    </div>

                                }
                            />
                        </List.Item>
                    )}
                />
            </section>
        )
    }
}

class Location extends Component {
    componentDidMount() {
        this.props.application.setState({isLoading: false})
    }
    render() {
        // https://wiki.openstreetmap.org/wiki/Tile_servers
        const getProvider = (x, y, z) => `https://cartodb-basemaps-a.global.ssl.fastly.net/light_all/${z}/${x}/${y}.png`;
        const { content: { latitude, longitude, content}} = this.props;
        const position = [
            latitude,
            longitude
        ];
        const href = 'http://maps.apple.com/?q=' + position.join(',');
        return (
            <section>
                <ReactMarkdown
                    source={content}
                    renderers={{
                        image: (props) => (
                            <span className="figure">
                                <img
                                    src={props.src}
                                    alt={props.alt}
                                    title={props.alt}
                                />
                                <span className="figcaption">{props.alt}</span>
                            </span>
                        )
                    }}
                />
                <div style={{ position: 'relative', top: -50}}>
                    <Button
                        type="primary"
                       shape="round"
                        icon="compass"
                        size="large"
                       style={{float: 'right'}}
                        href={href}
                      target="_blank">Navigation</Button>
                </div>
                <div className="map">
                    <ContainerDimensions>
                        {({width, height}) =>
                            <Map center={position} zoom={16} width={width} height={400} provider={getProvider}
                                 attribution={
                                     <span>
                                        Map tiles by <a href="https://carto.com/attribution/" target="_blank" rel="noopener noreferrer">Carto</a>, under CC BY 3.0.
                                        Data by <a href="http://openstreetmap.org" target="_blank" rel="noopener noreferrer">OpenStreetMap</a>, under ODbL.
                                     </span>
                                 }>
                                 <Marker anchor={position} payload={1} onClick={({event, anchor, payload}) => {}}/>
                            </Map>
                        }
                    </ContainerDimensions>
                </div>
            </section>
        )
    }
}

class Events extends Component {
    state = {
        events: [],
        filteredEvents: [],
        value: moment(),
        selectedValue: moment(),
    };

    async componentDidMount() {
        const {strapi} = this.props.application;

        const events = sort(
            await strapi.getEntries('events', {
                published: true
            })
        );

        this.setState({
            events: event_recurrent(
                events
            )
        });

        this.props.application.setState({isLoading: false})
    }

    filterEvents = (value) => {

        const events = [];

        this.state.events.forEach((event) => {

            if(
                event.date.getFullYear() === value._d.getFullYear()
             && event.date.getMonth() === value._d.getMonth()
             && event.date.getDate() === value._d.getDate()) {
                events.push(
                    event
                )
            }

        });

        return events

    };

    thisYearEvents = () => {

        const events = [], date = moment();

        this.state.events.forEach((event) => {

            if(
                event.date.getFullYear() === date._d.getFullYear()
             && event.type !== EventType.TRAINING
            ) {
                events.push(
                    event
                )
            }

        });

        return events

    };

    nextYearEvents = () => {

        const events = [], date = moment();

        this.state.events.forEach((event) => {

            if(
                event.date.getFullYear() === ( date._d.getFullYear() + 1)
             && event.type !== EventType.TRAINING
            ) {
                events.push(
                    event
                )
            }

        });

        return events

    };

    dateCellRender = (value) => {
        const listData = this.filterEvents(value);
        let status = 'default';
        if( listData.length) {
            if(!listData[0].type){debugger}
            switch (listData[0].type.trim()) {
                case 'intern': {
                    status = 'error';
                    break
                }
                case 'public': {
                    status = 'success';
                    break
                }
                default:{}
            }
        }
        return listData.length ? (
            <Badge status={status} dot/>
        ) : ''
    };

    onSelect = (value) => {
        this.setState({
            value,
            selectedValue: value,
        });
    };

    onPanelChange = (value) => {
        this.setState({ value });
    };

    download = (href, title) => {

        const {strapi: { axios}} = this.props.application;

        inlineDownload.call(
            this,
            axios,
            href,
            title
        );

    };

    render() {
        const { application: { strapi: { axios}}, content:{ content}} = this.props;
        const { value, events} = this.state;
        const filteredEvents = events ? this.filterEvents( value) : [],
              thisYearEvents = events ? this.thisYearEvents() : [],
              nextYearEvents = events ? this.nextYearEvents() : [];

        return <section>
            <ReactMarkdown
                source={content}
                renderers={{
                    image: (props) => (
                        <span className="figure">
                                <img
                                    src={props.src}
                                    alt={props.alt}
                                    title={props.alt}
                                />
                                <span className="figcaption">{props.alt}</span>
                            </span>
                    )
                }}
            />
            <Row>
                <Col lg={12} md={24} xs={24}>
                    <ConfigProvider locale={de_DE}>
                        <Calendar
                            className="calendar"
                            fullscreen={false}
                            value={value}
                            onSelect={this.onSelect}
                            onPanelChange={this.onPanelChange}
                            dateCellRender={this.dateCellRender}
                        />
                    </ConfigProvider>
                </Col>
                <Col lg={12} md={24} xs={24}>
                    <List
                        size="small"
                        header={<span><Icon type="calendar" /> {(new Date(value)).toLocaleDateString('de-DE', dateConfiguration)}</span>}
                        bordered
                        dataSource={filteredEvents}
                        renderItem={event => (<List.Item>{event.name}</List.Item>)}
                    />
                </Col>
            </Row>
            {[thisYearEvents,nextYearEvents].filter( events => {

                return 0 < events.length;
            }).map( events => {

                return <div>
                    <Divider />
                    <h2>Termin Jahresübersicht Pinnewitz für {events[0].date.getFullYear()}</h2>
                    <List
                        className="events"
                        itemLayout="horizontal"
                        dataSource={events}
                        renderItem={event => {
                            const actions = /*event.endDate ? [
                            <Button type="primary" icon="calendar" onClick={() => { this.downloadAsIcal( event)}}/>,
                        ] :*/ [];
                            if( !!event.download) {
                                actions.push(
                                    <Button type="primary" icon="download" onClick={() => {
                                        this.download(axios.defaults.baseURL + event.download.url, event.name);
                                    }}/>
                                );
                            }
                            return (
                                <List.Item actions={actions}>
                                    <List.Item.Meta
                                        title={<span><Icon type="calendar" /> {(new Date(event.date)).toLocaleDateString('de-DE', dateConfiguration)}</span>}
                                        description={event.name}
                                    />
                                </List.Item>
                            )
                        }}
                    />
                </div>
            })}
        </section>

    }
}

const groupBy = (results, property) => {
    const groups = [], hashs = [];

    results.forEach(result => {
        let key = typeof result[property] === 'object'? result[property].id : result[property];
        let index = hashs.indexOf(key);
        if (index > -1)
            groups[index].push(result);
        else {
            hashs.push(key);
            groups.push([result])
        }
    });

    return groups
};

const sortResults= (a, b) => {
    return a.total - b.total
        || a.r10 - b.r10
        || a.r9 - b.r9
        || a.r8 - b.r8
        || a.r7 - b.r7
        || a.r6 - b.r6
        || a.r5 - b.r5
        || a.r4 - b.r4
        || a.r3 - b.r3
        || a.r2 - b.r2
        || a.r1 - b.r1
        || a.sorting - b.sorting
};

const calculateTotal = (result) => {
    let total =
        ( result.r10 ? result.r10 * 10 : 0)
        + ( result.r9 ? result.r9 * 9 : 0)
        + ( result.r8 ? result.r8 * 8 : 0)
        + ( result.r7 ? result.r7 * 7 : 0)
        + ( result.r6 ? result.r6 * 6 : 0)
        + ( result.r5 ? result.r5 * 5 : 0)
        + ( result.r4 ? result.r4 * 4 : 0)
        + ( result.r3 ? result.r3 * 3 : 0)
        + ( result.r2 ? result.r2 * 2 : 0)
        + ( result.r1 ? result.r1 * 1 : 0);
    return Object.assign( result, {
        total: total
    })
};

class Competition extends Component {

    state = {
        competition: null
    };

    async componentDidMount() {

        const { application: {strapi}, competition} = this.props;

        const results = (await strapi.getEntries('results', {
            competition: competition.id
        })).map(calculateTotal).sort(sortResults).reverse();

        const disciplines = groupBy(
            results,
            'discipline'
        ).map(results => {
            const groups = groupBy(
                results,
                'group'
            );
            groups.forEach(group => {
                group.forEach( (result, index) => {
                    result.position = index + 1
                })
            });
            return groups
        }).map(groups => {
            return {
                id: groups[0][0].discipline.id,
                name: groups[0][0].discipline.name,
                shots: groups[0][0].discipline.shots,
                discipline: groups[0][0].discipline.discipline,
                groups: groups
            }
        });

        const compound = {};

        // build compound disciplines
        disciplines.forEach(discipline => {
            if( !!discipline.discipline) {
                compound[ discipline.discipline] = Array.prototype.concat.apply(
                    compound[ discipline.discipline] || [],
                    discipline.groups
                )
            }
        });

        // group participants
        await Promise.all(
            Object.entries(compound).map(async([key, value])=> {
                const discipline = await strapi.getEntry('disciplines', key);
                disciplines.push(
                    Object.assign( discipline, {
                        id: discipline.disciplines.map((discipline) => discipline.id).join('-'),
                        shots: discipline.disciplines.reduce((shots,discipline) => shots.shots + discipline.shots),
                        groups: [
                            groupBy(
                                value,
                                'participants'
                            ).filter(results => discipline.disciplines.length === results.length).map(results => {
                                // calculate total over all disciplines
                                const result = { r10:0, r9:0, r8:0, r7:0, r6:0, r5:0, r4:0, r3:0, r2:0, r1:0,
                                    id: results.map(result => result.id)
                                };
                                results.forEach(r => {
                                    Object.entries(result).forEach(([k,v])=>{
                                        result[k] = v + r[k];
                                    })
                                });
                                // build new result with children
                                return calculateTotal(
                                    Object.assign( {}, results[0], result, {
                                        id: result.id + '-' + discipline.id,
                                        children: results.map( result => {
                                            return Object.assign( {}, result, {
                                                'participants': result.discipline.name
                                            })
                                        }),
                                        position: 0,
                                        discipline: discipline
                                    })
                                )
                            }).sort(sortResults).reverse()
                        ]
                    })
                );
                return true
            })
        );

        disciplines.sort((a,b) => {
            return (a.name ?? 0).toString().localeCompare((b.name ?? 0).toString());
        })

        this.setState({
            disciplines
        })
    }

    render() {
        const { competition, application: { isMobile, isSmallMobile }} = this.props;
        const { disciplines} = this.state;

        let columns = [{
            title: 'Name',
            dataIndex: 'participants',
            width: 208,
            render: (text, record) => {
                const t = [ record.club, record.agegroup].filter(a => a);
                return text + ( 0  < t.length ? ' ('+t.join( ',')+')' : '')
            }
        }];

        if( !isSmallMobile) {

            columns = columns.concat([
                {
                    title: '10',
                    dataIndex: 'r10',
                    render: text => text || '-'
                }, {
                    title: '9',
                    dataIndex: 'r9',
                    render: text => text || '-'
                }, {
                    title: '8',
                    dataIndex: 'r8',
                    render: text => text || '-'
                }, {
                    title: '7',
                    dataIndex: 'r7',
                    render: text => text || '-'
                }, {
                    title: '6',
                    dataIndex: 'r6',
                    render: text => text || '-'
                }
            ])
        }

        if( !isMobile) {
            columns = columns.concat([{
                title: '5',
                dataIndex: 'r5',
                render: text => text || '-'
            }, {
                title: '4',
                dataIndex: 'r4',
                render: text => text || '-'
            }, {
                title: '3',
                dataIndex: 'r3',
                render: text => text || '-'
            }, {
                title: '2',
                dataIndex: 'r2',
                render: text => text || '-'
            }, {
                title: '1',
                dataIndex: 'r1',
                render: text => text || '-'
            }])
        }

        columns = columns.concat([
            {
                title: 'Total',
                dataIndex: 'total',
                width: 75,
                render: (text, record) => { return text + ' ('+ Math.round( text * 100 / ( 10 * ( record.discipline.shots || 15))) +'%)' }
            }, {
                title: 'P',
                dataIndex: 'id',
                width: 43,
                // fixed: 'right',
                render: (text, record, index) => record.position || index + 1
            }
        ]);

        return competition ? (
            <div>
                {disciplines ? disciplines.map(discipline => {
                    return (
                        <section key={competition.id + '-' + discipline.id}>
                            <h3>{discipline.name} ({discipline.shots} Schuss)</h3>
                            {discipline.groups.map(results => {
                                const title = !!results[0].group ? () => results[0].group : null;
                                return(
                                    <Table
                                        key={competition.id + '-' + discipline.id+'-'+results[0].group}
                                        columns={columns}
                                        dataSource={results}
                                        bordered
                                        title={title}
                                        pagination={false}
                                        rowKey={(record)=>discipline.id+'-'+record.id}
                                    />
                                )
                            })}
                        </section>
                    )
                }): ''}
            </div>
        ) : ''
    }
}

class Competitions extends Component {

    state = {
        competitions: []
    };

    async componentDidMount() {

        const {strapi} = this.props.application;

        const competitions = {};

        const __competitions = sort(await strapi.getEntries('competitions', {
            published: true
        })).reverse();

        __competitions.forEach((competition) => {
            let date = new Date(competition.date);
            if( !competitions[ date.getFullYear()]) {
                competitions[ date.getFullYear()] = []
            }
            competitions[ date.getFullYear()].push( competition)
        });

        this.setState({
            competitions: competitions
        });

        this.props.application.setState({isLoading: false})
    }

    render() {
        const years = [];

        for (const year in this.state.competitions){
            if (this.state.competitions.hasOwnProperty(year)) {
                years.push( year)
            }
        }

        years.sort().reverse();

        return (
            <section>
                {years.map((year) => {
                    this.state.competitions[year].sort(function(a,b){
                        return b.date - a.date;
                    });
                    return (
                        <div key={year}>
                            <br/>
                            <h2>{year}</h2>
                            <Collapse>
                                {this.state.competitions[year].map(competition => {
                                    return (
                                        <Panel key={competition.id} header={competition.name + ' (' + (new Date(competition.date)).toLocaleDateString('de-DE', dateConfiguration) + ')'}>
                                            <Competition application={this.props.application} competition={competition}/>
                                        </Panel>
                                    )
                                })}
                            </Collapse>
                        </div>
                    )
                })}
            </section>
        )
    }

}

class Links extends Component {
    state = {
        links: []
    };

    async componentDidMount() {

        const {strapi} = this.props.application;

        const links = await strapi.getEntries('links');

        links.sort((a,b) => {
            return a.title.localeCompare( b.title);
        }).sort((a,b) => {
            return a.group.localeCompare( b.group);
        });

        this.setState({
            links: groupBy( links, 'group')
        });

        this.props.application.setState({isLoading: false})
    }

    render() {
        const {links} = this.state;
        return(
            <section>
                <h2>{this.props.content.title}</h2>
                <ReactMarkdown
                    source={this.props.content.content}
                    renderers={markDownRenderer}
                />
                {links.map((group) => {
                    const columns = [
                        {
                            title: group[0].group,
                            dataIndex: 'href',
                            render: (text, record) => <a
                                href={record.href}
                                alt={record.title}
                                title={record.title}
                                target="_blank"
                                rel="noopener noreferrer">
                                <Icon type="link" /> {record.title}
                            </a>
                        }
                    ];
                    return (
                        <Table
                            key={group[0].group}
                            columns={columns}
                            dataSource={group}
                            bordered
                            pagination={false}
                            rowKey={(record)=>record.id}
                        />
                    )
                })}
            </section>
        )
    }
}

class Downloads extends Component {
    state = {
        downloads: []
    };

    async componentDidMount() {

        const {strapi} = this.props.application;

        const downloads = await strapi.getEntries('downloads');

        const yesterday = moment.utc().startOf('day');

        (await strapi.getEntries('events', {
            published: true
        })).forEach((event) => {
            if( !!event.download && moment( event.date)> yesterday) {
                downloads.push({
                    id: event.id,
                    title: event.name,
                    file: event.download,
                    group: 'Ausschreibungen'
                });
            }
        });

        downloads.sort((a, b) => {
            return a.group.localeCompare( b.group);
        });

        this.setState({
            downloads: groupBy( downloads, 'group')
        });

        this.props.application.setState({isLoading: false})

    }

    download = (href, title) => {

        const {strapi: { axios}} = this.props.application;

        inlineDownload.call(
            this,
            axios,
            href,
            title
        );

    };

    render() {
        const {downloads} = this.state;
        const {strapi} = this.props.application;

        const self = this;

        return(
            <section>
                <h2>{this.props.content.title}</h2>
                <ReactMarkdown
                    source={this.props.content.content}
                    renderers={markDownRenderer}
                />
                {downloads.map((group) => {
                    const columns = [
                        {
                            title: group[0].group,
                            dataIndex: 'href',
                            render: (text, record) => {
                                const href = strapi.axios.defaults.baseURL + record.file.url;
                                // eslint-disable-next-line
                                return <a
                                    onClick={()=>{
                                        self.download(href, record.title)
                                    }}
                                    alt={record.title}
                                    title={record.title}
                                    rel="noopener noreferrer"
                                    download={record.title}>
                                    <Icon type="download" /> {record.title}
                                </a>
                            }
                        }
                    ];
                    return (
                        <Table
                            key={group[0].group}
                            columns={columns}
                            dataSource={group.sort((a,b) => a.title.localeCompare(b.title))}
                            bordered
                            pagination={false}
                            rowKey={(record)=>record.id}
                        />
                    )
                })}
            </section>
        )
    }
}

class Gallery extends Component {
    state = {
        galleries: [],
        isOpen: false,
        photos: null,
        photoIndex: 0
    };

    async componentDidMount() {

        const {strapi} = this.props.application;

        const galleries = (await strapi.getEntries('galleries')).map(gallery => {
            gallery.date = moment(gallery.date);
            return gallery;
        });

        galleries.sort((a, b) => {
            return a.date - b.date;
        }).reverse();

        galleries.forEach((gallery) => {
            gallery.images.forEach((image) => image.url = strapi.axios.defaults.baseURL + image.url);
            gallery.images.sort((a, b) => {
                return a.name.localeCompare( b.name);
            });
        });

        this.setState({
            galleries: galleries
        });

        this.props.application.setState({isLoading: false})

    };

    render() {

        const {galleries, isOpen, photoIndex, photos} = this.state;

        return (
            <section>
                <Row>
                    {galleries.map((gallery) => {
                        return <Col span={8} xs={24} sm={12} lg={8} key={gallery.id}>
                            <Card
                                key={gallery.id}
                                hoverable
                                style={{ height: 244 }}
                                cover={<img alt={gallery.name} src={gallery.images[0].url} />}
                                onClick={()=>{
                                    this.setState({
                                        isOpen: true,
                                        photos: gallery.images,
                                        photoIndex: 0
                                    });
                                }}
                            >
                                <Meta title={gallery.name}/>
                            </Card>
                        </Col>
                    })}
                </Row>
                {isOpen && (
                  <Lightbox
                    mainSrc={photos[photoIndex].url}
                    nextSrc={photos[(photoIndex + 1) % photos.length].url}
                    prevSrc={photos[(photoIndex + photos.length - 1) % photos.length].url}
                    onCloseRequest={() => this.setState({ isOpen: false })}
                    onMovePrevRequest={() =>
                      this.setState({
                          photoIndex: (photoIndex + photos.length - 1) % photos.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                          photoIndex: (photoIndex + 1) % photos.length,
                      })
                    }
                  />
                )}
            </section>
        )

    };
}

class Content extends Component {
    render() {
        const {content, application} =  this.props;

        if( !content) { return ''}

        let renderedContent = '';

        switch ( content.type.trim()) {
            case 'contact': {
                renderedContent = <Contact content={content} application={application}/>;
                break
            }

            case 'location': {
                renderedContent = <Location
                    content={content}
                    application={application}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />;
                break
            }

            case 'events': {
                renderedContent = <Events content={content} application={application}/>;
                break
            }

            case 'competitions': {
                renderedContent = <Competitions content={content} application={application}/>;
                break
            }

            case 'links': {
                renderedContent = <Links content={content} application={application}/>;
                break
            }

            case 'downloads': {
                renderedContent = <Downloads content={content} application={application}/>;
                break
            }

            case 'gallery': {
                renderedContent = <Gallery content={content} application={application}/>;
                break
            }

            default: {
                renderedContent = <Markdown content={content} application={application}/>
            }
        }

        return renderedContent
    }

}

export default Content
