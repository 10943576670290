// IE11 fixes
import 'babel-polyfill'
import 'es6-promise'

import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import { Row, Col, Icon, Button, Drawer, BackTop } from 'antd'

import './App.css'

import Menu from './components/Menu'
import Slider from './components/Slider'
import Strapi from 'strapi-sdk-javascript/build/main'

import { Page, Page404} from "./components/Page";

import slugify from "./functions/slugify"

import logoSrc from './assets/logo.svg'

class App extends Component {

    constructor (props) {
        super(props);
        const self = this;
        this.state = {
            slug: window.location.pathname,
            page: null,
            pages: {},
            strapi: new Strapi('https://backend.schuetzengesellschaftziegenhain.de'),
            setState: state => {
                if( state.slug) {
                    window.history.pushState(null, '', state.slug);
                    state.page = this.state.pages[ state.slug];
                    state.drawerVisible = false;
                    state.isLoading = true;
                }
                self.setState( state)
            },
            isMobile: window.innerWidth < 768,
            isSmallMobile: window.innerWidth < 480,
            drawerVisible: false,
            isLoading: true
        };
    }

    handleWindowResize = () => {
            this.setState({ isMobile: window.innerWidth < 768, isSmallMobile: window.innerWidth < 480 });
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    };

    async componentDidMount() {

        const pages = {};

        const allPages = await this.state.strapi.getEntries('pages', {published: true} );

        const home = allPages.filter(page => page.home)[0];

        pages[ '/'] = home;

        allPages.filter(page => !page.home).forEach( page => {

            pages[ slugify( page.title)] = page;

            page.pages.filter(p => p.published).forEach( _page => {

                allPages.forEach( __page => {
                    if( __page.id === _page.id){
                        pages[ slugify( [ page.title, _page.title] )] = __page
                    }
                })

            })

        });

        const state = {pages: pages};

        if( !this.state.page) {

            state.page = pages[ this.state.slug];

            this.setState(state);

        } else {

            this.setState( { slug: '/'});

        }

        window.addEventListener('resize', this.handleWindowResize);

    }

    showDrawer = () => {
        this.setState({
            drawerVisible: true,
        });
    };

    closeDrawer = () => {
        this.setState({
            drawerVisible: false,
        });
    };

    render() {

        const {page, isMobile, drawerVisible} = this.state;

        const logo = (
            <Link to="/" onClick={()=>this.state.setState({slug: '/'})}>
                <img id="logo" src={logoSrc} alt="Schützengesellschaft Ziegenhain 1870 e.V." title="Schützengesellschaft Ziegenhain 1870 e.V."/>
            </Link>
        );

        const navigation = (
            <Row>
                <Col xs={0} sm={2} lg={4}></Col>
                <Col xs={23} sm={19} lg={14} >
                    <Menu application={this.state}/>
                </Col>
                <Col xs={1} sm={1} lg={1} >{logo}</Col>
                <Col xs={0} sm={2} lg={5}></Col>
            </Row>
        );

        const burgerMenu = (
            <Row>
                <Col xs={1} sm={1}></Col>
                <Col xs={3} sm={2}>
                    {logo}
                </Col>
                <Col xs={15} sm={18}></Col>
                <Col xs={4} sm={2}>
                    <Button className="barsMenu" type="primary" onClick={this.showDrawer} aria-label="Menü" >
                        <Icon type="menu-unfold" />
                    </Button>
                    <Drawer
                        title="Navigation"
                        placement="right"
                        closable={true}
                        onClose={this.closeDrawer}
                        visible={drawerVisible}
                    >
                        <Menu application={this.state} options={{ mode: 'inline'}}/>
                    </Drawer>
                </Col>
                <Col xs={1} sm={1}></Col>
            </Row>
        );

        if( !this.state.pages['/']) {
            return (
                <div className="pulse" style={{
                    opacity: 0.8,
                    position: 'fixed',
                    width: '100%',
                    height: '100%',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                    background: 'url(' + logoSrc + ') center center no-repeat'
                }}>
                </div>
            )
        }

        return (
            <Router statusCode={page ? 200 : 404}>
                <div>
                    <header>
                        <nav>
                            { isMobile ? burgerMenu : navigation }
                        </nav>
                        { page && <Slider application={this.state} slideshows={page ? page.slideshows : []}/>}
                    </header>
                    {
                        page ? (
                            <slot>
                                <BackTop visibilityHeight={600} />
                                <Route render={ (props)=> <Page application={this.state}/>}/>
                            </slot>
                        ) : (
                            <slot>
                                <Page404 application={this.state}/>
                            </slot>
                        )
                    }
                    <footer>
                        <Row>
                            <Col md={4} xs={0}></Col>
                            <Col md={14} xs={24}>
                                Copyright © {(new Date()).getFullYear()} Schützengesellschaft Ziegenhain 1870
                                e.V.. Alle Rechte vorbehalten.
                            </Col>
                            <Col md={6} xs={0}></Col>
                        </Row>
                    </footer>
                </div>
            </Router>
        );
    }
}

export default App;
