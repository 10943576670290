const yearEnd = (new Date()).getFullYear() + 2;

const weekly = event => {

    const date = new Date(event.date), events = [];

    while( date.getFullYear() < yearEnd) {

        date.setDate( date.getDate() + 7);

        const _date = new Date( date.toUTCString());

        events.push(
            Object.assign(
                {},
                event,
                {
                    date: _date,
                    original: event,
                }
            )
        )

    }

    return events;

};

export default events =>  {

    let _events = events.map( event => {
        event.date = new Date(event.date);
        return event
    });

    const nonRecurrentDates = [];

    events.forEach( ({date,recurrent}) => {
        if( !recurrent) {
            nonRecurrentDates.push(
                date.toISOString().slice(0,10).replace(/-/g,"")
            )
        }
    });

    events.forEach( event => {
        switch( event.recurrent) {
            case 'weekly': {
                _events = _events.concat(
                    weekly( event)
                );
                break
            }
            default: break
        }
    });

    return _events.filter(({date,recurrent}) => {
        return !recurrent || 0 > nonRecurrentDates.indexOf(
            date.toISOString().slice(0,10).replace(/-/g,"")
        )
    }).sort(function(a,b){
        return b.date - a.date;
    }).reverse();

}
