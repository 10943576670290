
export default elements =>  {

    const sorted = [], processed = [];

    while( elements.length > sorted.length) {

        let doneSomething = false;

        elements.forEach( element => {

            if( processed[ element.id]) {

                return

            }

            if( !element.previous
            || ( element.previous && ( processed[ element.previous] || processed[ element.previous.id]))) {

                sorted.push( element);

                processed[ element.id] = true;

                doneSomething = true

            }

        });

        if( !doneSomething) {

            break

        }

    }

    return sorted;

}
