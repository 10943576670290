import React, { Component } from 'react'
import LoadingOverlay from 'react-loading-overlay';

import { Carousel } from 'antd';

class Slide extends Component {

    state = {
        isMobile: window.innerWidth < 768
    };

    handleWindowResize = () => {
        this.setState({ isMobile: window.innerWidth < 768 });
    };

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    render() {
        const {image} = this.props;
        const height = this.state.isMobile ? 150 : 300;

        return (
            <div style={{
                "background": 'url('+ image + ') no-repeat center center',
                backgroundSize: 'cover',
                width: '100%',
                minHeight: height}}>
            </div>
        )
    }
}

class Slider extends Component {

    static defaultProps = {
        _autoplay: true,
        _autoplaySpeed: 10000,
        _speed: 2000,
        _pauseOnDotsHover: true,
        _initialSlide: 0,
        _effect: 'scrollx'
    };

    state = {
        slides: [],
        isLoading: true
    };

    async componentDidMount(prevProps, prevState) {
        const strapi = this.props.application.strapi;

        let slides = [];

        await Promise.all(
          (this.props.slideshows || []).map( async show => {
              show = (
                await strapi.getEntries(
                  'slideshows',
                  {
                      id: show.id,
                      published: true
                  }
                )
              )[0];

              if( show) {
                  slides = slides.concat( show.images.map(image => {
                      return {
                          id: image.id,
                          url: strapi.axios.defaults.baseURL + image.url
                      }
                  }))
              }
          })
        );

        const self = this;

        slides.forEach(({url},index)=>{
            let i = new Image();
            if( 0 === index) {
                i.onload = () => {
                    self.setState({
                        slides: slides,
                        isLoading: false
                    })
                }
            }
            i.src = url;
        })
    }

    // async componentWillReceiveProps(nextProps) {
    //
    //
    //
    // }

    render() {
        const { 
            slideshows: [
                { autoplay, autoplaySpeed, speed, pauseOnDotsHover, initialSlide, effect }
            ],
            _autoplay, _autoplaySpeed, _speed, _pauseOnDotsHover, _initialSlide, _effect } = this.props;
        const { slides, isLoading } = this.state;
        return (
            <LoadingOverlay
                active={isLoading}
                spinner
                text='Bitte warten...'
            >
                <Carousel
                    initialSlide={ initialSlide || _initialSlide}
                    effect={ effect || _effect}
                    autoplay={typeof(autoplay) === 'boolean' ? autoplay : _autoplay}
                    autoplaySpeed={autoplaySpeed || _autoplaySpeed}
                    speed={speed || _speed}
                    pauseOnDotsHover={typeof(autoplay) === 'boolean' ? pauseOnDotsHover : _pauseOnDotsHover}>
                    {slides.map( slide => {
                        return (
                            <Slide key={slide.id} image={slide.url}/>
                        )
                    })}
                </Carousel>
            </LoadingOverlay>
        )

    }

}

export default Slider;
