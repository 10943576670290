import React, { Component } from 'react';
import { Menu, Icon } from 'antd';

import slugify from './../functions/slugify'
import sort from './../functions/dependency_sorting'

const SubMenu = Menu.SubMenu;

class AppMenu extends Component {

    state = {
        pages: [],
    };

    async componentDidMount() {

        const {slug, pages} = this.props.application;

        const home = Object.values( pages).filter(page => page.home)[0];

        this.setState( { slug: slug} );

        const items = [
            <Menu.Item key={'/'}>
                <Icon type="home" />{home.slug || home.title}
            </Menu.Item>
        ];

        sort(
            Object.values( pages).filter(page => !page.home && page.parent && page.parent.id === home.id && page.published)
        ).forEach(page => {
            const icon = page.icon ? (
                <Icon type={page.icon} />
            ) : '';
            if( page.pages && page.pages.length) {
                items.push(
                    <SubMenu key={slugify(page.title)} title={<span>{icon}{page.slug || page.title}</span>}>
                        {sort(page.pages).filter( page => page.published).map(sub => {
                            const subIcon = sub.icon ? (
                                <Icon type={sub.icon} />
                            ) : '';
                            return <Menu.Item key={slugify([ page.slug || page.title, sub.slug || sub.title])}>
                                {subIcon}{sub.slug || sub.title}
                            </Menu.Item>
                        })}
                    </SubMenu>
                )
            } else (
                items.push(
                    <Menu.Item key={slugify(page.slug || page.title)}>
                        {icon}{page.slug || page.title}
                    </Menu.Item>
                )
            )
        });

        this.setState({ pages: items});

    }

    handleClick = (e) => {
        this.props.application.setState({slug: e.key})
    };

    render() {

        const {slug} = this.props.application;

        const option = Object.assign(
            {
                mode: 'horizontal'
            },
            this.props.options || {}
        );

        return (
            <Menu
                onClick={this.handleClick}
                selectedKeys={[slug]}
                mode={option.mode}
            >
                {this.state.pages.map(page => {
                    return page
                })}
            </Menu>
        );
    }
}

export default AppMenu;
