import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { ConfigProvider } from 'antd';
import de_DE from 'antd/lib/locale-provider/de_DE';
import moment from 'moment';
import 'moment/locale/de';

moment.locale('de');

ReactDOM.render(<ConfigProvider locale={de_DE}><App /></ConfigProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
