import React, { Component } from 'react'
import { Row, Col, List, Icon } from 'antd'

import event_recurrent from '../functions/event_recurrent'
import sort from './../functions/dependency_sorting'

import Content from './Content'


const dateConfiguration = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };


class Page extends Component {

    state = {
        events: []
    };

    async componentDidMount() {

        const {strapi} = this.props.application;

        const events = event_recurrent(
            await strapi.getEntries('events', { published: true})
        );

        const today = new Date();

        while( 0 < events.length && today > events[ 0].date) {
            events.shift()
        }

        this.setState({
            events: events.slice( 0, 5)
        })

    }

    render() {
        const {page} = this.props.application;

        if( page) {
            document.title = page.slug || page.title;
        }

        return (
            <Row>
                <Col lg={4} md={2} xs={0}></Col>
                <Col lg={14} md={16} xs={24}>
                    <h1>{page ? page.title : ''}</h1>
                    <article>
                        { page && page.contents ? sort(page.contents).map(content => {
                            return (
                                <Content key={content.id} content={content} application={this.props.application}/>
                            )
                        }) : ''}
                    </article>
                </Col>
                <Col lg={6} md={6} xs={24}>
                    <List
                        className="events"
                        itemLayout="horizontal"
                        dataSource={this.state.events}
                        renderItem={event => {
                            return (
                                <List.Item>
                                    <List.Item.Meta
                                        title={<span><Icon type="calendar" /> {(new Date(event.date)).toLocaleDateString('de-DE', dateConfiguration)}</span>}
                                        description={event.name}
                                    />
                                </List.Item>
                            )
                        }}
                    />
                </Col>
            </Row>
        )
    }

}

class Page404 extends Page {
    render() {
        return (
            <Row>
                <Col lg={4} md={2} xs={0}></Col>
                <Col lg={14} md={16} xs={24}>
                    <h1>Seite ist nicht verfügbar (404)</h1>
                    <p>Die von Ihnen gesuchte Seite ist nicht verfügbar, bitte wählen Sie eine andere Seite aus dem Menü aus.</p>
                </Col>
                <Col lg={6} md={6} xs={24}>
                    <List
                        className="events"
                        itemLayout="horizontal"
                        dataSource={this.state.events}
                        renderItem={event => {
                            return (
                                <List.Item>
                                    <List.Item.Meta
                                        title={<span><Icon type="calendar" /> {(new Date(event.date)).toLocaleDateString('de-DE', dateConfiguration)}</span>}
                                        description={event.name}
                                    />
                                </List.Item>
                            )
                        }}
                    />
                </Col>
            </Row>
        )
    }
}

export {
    Page,
    Page404
};
